import FetchWrapper from '../../Network/FetchWrapper'
import MemberRepo from './MemberRepo'
import MemberRegisterInformationDTO from '../DTO/MemberRegisterInformationDTO'
import {DTO} from '../../Common/DtoUtilities'
import {SignboardDTO, SignboardJSON} from '../DTO/SignboardDTO'
import {MemberSelfDTO, MemberSelfJSON} from '../DTO/MemberSelfDTO'
import {CommonResponseDTO, CommonResponseJSON} from '../../Network/CommonResponse'
import {MemberAddressDTO} from '../DTO/MemberAddressDTO'
import {Nullable, Optional} from '../../Common/TypeHelper'
import {InvitationInfoDTO, InvitationInfoJSON} from '../DTO/InvitationInfoDTO'
import {MemberMailUpdateDTO} from '../DTO/MemberMailUpdateDTO'
import {MemberJoinBucketDTO} from '../DTO/MemberJoinBucketDTO'
import {PaymentMethodDTO, PaymentMethodJSON} from '../DTO/PaymentMethodDTO'
import {BucketInfoDTO, BucketInfoJSON} from '../DTO/BucketInfoDTO'
import {MemberBucketOptionsDTO, MemberBucketOptionsJSON} from '../DTO/MemberBucketOptionsDTO'
import {BucketLeaveDTO, BucketLeaveJSON} from '../DTO/BucketLeaveDTO'
import {BucketRegisterResponseDataDTO, BucketRegisterResponseDataJSON, FreeBucketRegisterResponseDataDTO, FreeBucketRegisterResponseDataJSON, isFreeBucketResponseJSON} from '../DTO/BucketRegisterResponseDataDTO'
import {MemberContractInfoDTO, MemberContractInfoJSON} from '../DTO/MemberContractInfoDTO'
import {withMembersAuth} from '../../Network/BrowserFetchWrapper'
import {MemberVerifyIdentityDTO} from '../DTO/MemberVerifyIdentityDTO'
import {MemberVerifyIdentityInfoDTO, MemberVerifyIdentityInfoJSON} from '../DTO/MemberVerifyIdentityInfoDTO'
import {PreMemberRegisterInformationDTO} from '../DTO/PreMemberRegisterInformationDTO'
import {MobileCarrierStartPaymentInfo, MobileCarrierStartPaymentInfoDTO} from '../DTO/MobileCarrierStartPaymentInfoDTO'
import {MemberRegisterResult, MemberRegisterResultDTO, MemberRegisterResultJSON} from '../DTO/MemberRegisterResultDTO'
import {ConvenienceStoreKey, UsingMyCreditCardInfo} from '../Props/ContractInputInfo'
import {UsingMyCreditCardInfoDTO} from '../DTO/UsingMyCreditCardInfoDTO'
import {MemberPreJoinBucketDTO} from '../DTO/MemberPreJoinBucketDTO'
import {SignupPreRegisterInfoDTO, SignupPreRegisterInfoJSON} from '../DTO/SignupPreRegisterInfoDTO'
import {CvsContractDataJSON, CvsContractDTO, isCvsContractResponseJSON} from '../DTO/CvsContractDTO'
import {JoinPreRegisterInfoDTO, JoinPreRegisterInfoJSON} from '../DTO/JoinPreRegisterInfoDTO'
import {MemberShippingAddressDTO, MemberShippingAddressInfoDTO, ShippingAddressInfoJSON} from '../DTO/MemberShippingAddressDTO'
import {SignedUpBucketInfoDTO, SignedUpBucketInfoJSON} from '../DTO/SignedUpBucketInfoDTO'
import {MemberEditContractDTO} from '../DTO/MemberEditContractDTO'
import {ContractChangeResultDTO, ContractChangeResultJSON} from '../DTO/ContractChangeResultDTO'
import MemberReRegisterDTO from '../DTO/MemberReRegisterDTO'
import {MemberInvitation} from '../DTO/MemberInvitationDTO'
import {CreditCard3DSInfo, CreditCard3DSInfoJSON, fromJSON as fromCreditCard3DSInfoJSON, isCreditCard3DSResponseJSON} from '../DTO/CreditCard3DSInfoDTO'
import {CancelBucketLeaveResultDTO, CancelBucketLeaveResultJSON} from '../DTO/CancelBucketLeaveResultDTO'
import {MemberCancelBucketLeaveDTO} from '../DTO/MemberCancelBucketLeaveDTO'

class NetworkMemberRepo implements MemberRepo {

  private fetchWrapper: FetchWrapper
  private serverUrl = process.env.REACT_APP_MEMBUCKET_ADMIN_APP_SERVER_URL

  constructor(fetchWrapper: FetchWrapper) {
    this.fetchWrapper = fetchWrapper
  }

  sendOneTimeUrlMail(
    memberInvitation: MemberInvitation,
  ): Promise<CommonResponseDTO<null>> {
    return this.fetchWrapper.postJson(`${this.serverUrl}/v1/m/p/members/invitation`,
      DTO.toObject(memberInvitation),
    ).then((response: CommonResponseJSON<null>) => {
      return CommonResponseDTO.from(
        response,
        CommonResponseDTO.NO_CONVERT,
      )
    })
  }

  getInvitationInfo(invitationId: string): Promise<CommonResponseDTO<Nullable<InvitationInfoDTO>>> {
    return this.fetchWrapper.getByJson(`${this.serverUrl}/v1/m/p/members/${invitationId}`)
      .then((response: CommonResponseJSON<InvitationInfoJSON>) => {
        return CommonResponseDTO.from(
          response,
          InvitationInfoDTO.fromJSON,
        )
      })
  }

  getSignboardBySignupKey(signupKey: string): Promise<CommonResponseDTO<Nullable<SignboardDTO>>> {
    return this.fetchWrapper.getByJson(`${this.serverUrl}/v1/m/p/signboards/${signupKey}`)
      .then((response: CommonResponseJSON<SignboardJSON>) => {
        return CommonResponseDTO.from(
          response,
          SignboardDTO.fromJSON,
        )
      })
  }

  postCreateMember(
    memberRegisterInformationDTO: MemberRegisterInformationDTO,
  ): Promise<CommonResponseDTO<Nullable<CreditCard3DSInfo
    | BucketRegisterResponseDataDTO
    | CvsContractDTO
    | FreeBucketRegisterResponseDataDTO>>> {
    return this.fetchWrapper.postJson(`${this.serverUrl}/v1/m/p/members`,
      DTO.toObject(memberRegisterInformationDTO),
    ).then((
      response: CommonResponseJSON<CreditCard3DSInfoJSON
        | BucketRegisterResponseDataJSON
        | CvsContractDataJSON
        | FreeBucketRegisterResponseDataJSON>,
    ) => {
      if (isCvsContractResponseJSON(response)) {
        return CommonResponseDTO.from(response, CvsContractDTO.fromJSON)
      }
      if (isFreeBucketResponseJSON(response)) {
        return CommonResponseDTO.from(response, FreeBucketRegisterResponseDataDTO.fromJSON)
      }
      if (isCreditCard3DSResponseJSON(response)) {
        return CommonResponseDTO.from(response, fromCreditCard3DSInfoJSON)
      }
      return CommonResponseDTO.from(
        response as CommonResponseJSON<BucketRegisterResponseDataJSON>,
        BucketRegisterResponseDataDTO.fromJSON,
      )
    })
  }

  postCreateMemberConfirm(
    temporaryEnrollmentId: string,
  ): Promise<CommonResponseDTO<Nullable<MemberRegisterResult>>> {
    return this.fetchWrapper.postJson(
      `${this.serverUrl}/v1/m/p/members/${temporaryEnrollmentId}/credit_card_signup`,
      {},
    ).then((response: CommonResponseJSON<MemberRegisterResultJSON>) => {
      return CommonResponseDTO.from(
        response,
        MemberRegisterResultDTO.fromJSON,
      )
    })
  }

  putMemberReRegister(memberReRegisterDTO: MemberReRegisterDTO): Promise<CommonResponseDTO<null>> {
    return this.fetchWrapper.putJson(`${this.serverUrl}/v1/m/r/members/self/required_info_re_register`,
      DTO.toObject(memberReRegisterDTO), withMembersAuth(),
    ).then((response: CommonResponseJSON<null>) => {
        return new CommonResponseDTO(
          response.status,
          response.data,
          response.errorMessageList,
        )
      },
    )
  }

  postCreatePreMember(
    preMemberRegisterInformationDTO: PreMemberRegisterInformationDTO,
  ): Promise<CommonResponseDTO<Nullable<MobileCarrierStartPaymentInfoDTO>>> {
    return this.fetchWrapper.postJson(`${this.serverUrl}/v1/m/p/premembers`,
      DTO.toObject(preMemberRegisterInformationDTO),
    ).then((response: CommonResponseJSON<MobileCarrierStartPaymentInfoDTO>) => {
      return CommonResponseDTO.from(
        response,
        MobileCarrierStartPaymentInfoDTO.fromJSON,
      )
    })
  }

  getSignupPreRegisterInfo(
    temporaryEnrollmentId: string,
  ): Promise<CommonResponseDTO<Nullable<SignupPreRegisterInfoDTO>>> {
    return this.fetchWrapper.getByJson(`${this.serverUrl}/v1/m/p/premembers/${temporaryEnrollmentId}`)
      .then((response: CommonResponseJSON<SignupPreRegisterInfoJSON>) => {
        return CommonResponseDTO.from(
          response,
          SignupPreRegisterInfoDTO.fromJSON,
        )
      })
  }

  getSignupComplete(
    temporaryEnrollmentId: string,
  ): Promise<CommonResponseDTO<Nullable<MemberRegisterResultDTO>>> {
    return this.fetchWrapper.getByJson(`${this.serverUrl}/v1/m/p/members/registration/${temporaryEnrollmentId}`, {})
      .then((response: CommonResponseJSON<MemberRegisterResultJSON>) => {
        return CommonResponseDTO.from(
          response,
          MemberRegisterResultDTO.fromJSON,
        )
      })
  }

  getEditContractResult(
    temporaryEnrollmentId: string,
  ): Promise<CommonResponseDTO<Nullable<ContractChangeResultDTO>>> {
    return this.fetchWrapper.getByJson(
      `${this.serverUrl}/v1/m/r/buckets/${temporaryEnrollmentId}`, withMembersAuth(),
    )
      .then((response: CommonResponseJSON<ContractChangeResultJSON>) => {
        return CommonResponseDTO.from(
          response,
          ContractChangeResultDTO.fromJSON,
        )
      })
  }

  postOrderForUpdateMail(memberMailUpdateDTO: MemberMailUpdateDTO)
    : Promise<CommonResponseDTO<null>> {
    return this.fetchWrapper.postJson(`${this.serverUrl}/v1/m/r/members/self/mail/update`,
      DTO.toObject(memberMailUpdateDTO), withMembersAuth(),
    ).then((response: CommonResponseJSON<null>) => {
      return new CommonResponseDTO(
        response.status,
        response.data,
        response.errorMessageList,
      )
    })
  }

  putMailUpdateComplete(mailUpdateId: string): Promise<CommonResponseDTO<null>> {
    return this.fetchWrapper.putJson(`${this.serverUrl}/v1/m/r/members/self/mail/update/${mailUpdateId}`,
      {}, withMembersAuth(),
    ).then((response: CommonResponseJSON<null>) => CommonResponseDTO.ofNullData(response))
  }

  putPassword(password: string): Promise<CommonResponseDTO<null>> {
    return this.fetchWrapper.putJson(`${this.serverUrl}/v1/m/r/members/self/password/update`,
      {password: password}, withMembersAuth(),
    ).then((response: CommonResponseJSON<null>) => CommonResponseDTO.ofNullData(response))
  }

  putAddress(
    memberAddressDTO: MemberAddressDTO,
    sendMail: boolean,
  ): Promise<CommonResponseDTO<null>> {
    return this.fetchWrapper.putJson(`${this.serverUrl}/v1/m/r/members/self/address`,
      {
        ...DTO.toObject(memberAddressDTO),
        sendMail: sendMail,
      },
      withMembersAuth(),
    ).then((response: CommonResponseJSON<null>) => CommonResponseDTO.ofNullData(response))
  }

  putSubscribeMailMagazineIdList(
    bucketKey: string,
    subscribeMailMagazineIdList: number[],
  ): Promise<CommonResponseDTO<null>> {
    return this.fetchWrapper.putJson(
      `${this.serverUrl}/v1/m/r/members/self/${bucketKey}/mail_magazines`,
      {subscribeMailMagazineIdList: subscribeMailMagazineIdList},
      withMembersAuth(),
    ).then((response: CommonResponseJSON<null>) => CommonResponseDTO.ofNullData(response))
  }

  putNickname(
    bucketKey: string,
    nickname: Optional<string>,
  ): Promise<CommonResponseDTO<null>> {
    return this.fetchWrapper.putJson(
      `${this.serverUrl}/v1/m/r/members/self/${bucketKey}/nickname`,
      {nickname: nickname},
      withMembersAuth(),
    ).then((response: CommonResponseJSON<null>) => CommonResponseDTO.ofNullData(response))
  }

  getSelf(): Promise<CommonResponseDTO<Nullable<MemberSelfDTO>>> {

    return this.fetchWrapper.getByJson(`${this.serverUrl}/v1/m/r/members/self`, withMembersAuth())
      .then((response: CommonResponseJSON<MemberSelfJSON>) => {
        return CommonResponseDTO.from(
          response,
          MemberSelfDTO.fromJSON,
        )
      })
  }

  getSelfCreditCards(): Promise<CommonResponseDTO<UsingMyCreditCardInfoDTO[]>> {
    return this.fetchWrapper.getByJson(`${this.serverUrl}/v1/m/r/members/self/creditcards`, withMembersAuth())
      .then((response: CommonResponseJSON<UsingMyCreditCardInfo[]>) =>
        CommonResponseDTO.fromArray(response, UsingMyCreditCardInfoDTO.fromJSON))
  }

  postBucketForLeave(
    bucketKey: string,
    bucketLeaveReason: string,
  ): Promise<CommonResponseDTO<null>> {
    return this.fetchWrapper.postJson(
      `${this.serverUrl}/v1/m/r/members/self/buckets/${bucketKey}/leave`,
      {bucketLeaveReason: bucketLeaveReason},
      withMembersAuth(),
    ).then((response: CommonResponseJSON<null>) => CommonResponseDTO.ofNullData(response))
  }

  postBelongToBucket(memberJoinBucketDTO: MemberJoinBucketDTO):
    Promise<CommonResponseDTO<Nullable<CreditCard3DSInfo
      | BucketRegisterResponseDataDTO
      | CvsContractDTO
      | FreeBucketRegisterResponseDataDTO>>> {
    return this.fetchWrapper.postJson(`${this.serverUrl}/v1/m/r/members/self/bucket`,
      DTO.toObject(memberJoinBucketDTO), withMembersAuth(),
    ).then((response: CommonResponseJSON<CreditCard3DSInfoJSON
      | BucketRegisterResponseDataJSON
      | CvsContractDataJSON
      | FreeBucketRegisterResponseDataJSON>,
    ) => {

      if (isCvsContractResponseJSON(response)) {
        return CommonResponseDTO.from(response, CvsContractDTO.fromJSON)
      }
      if (isFreeBucketResponseJSON(response)) {
        return CommonResponseDTO.from(response, FreeBucketRegisterResponseDataDTO.fromJSON)
      }
      if (isCreditCard3DSResponseJSON(response)) {
        return CommonResponseDTO.from(response, fromCreditCard3DSInfoJSON)
      }

      return CommonResponseDTO.from(
        response as CommonResponseJSON<BucketRegisterResponseDataJSON>,
        BucketRegisterResponseDataDTO.fromJSON,
      )
    })
  }

  postBelongToBucketConfirm(
    temporaryEnrollmentId: string,
  ): Promise<CommonResponseDTO<Nullable<MemberRegisterResultDTO>>> {
    return this.fetchWrapper.postJson(
      `${this.serverUrl}/v1/m/p/members/${temporaryEnrollmentId}/credit_card_join`,
      {},
    ).then((response: CommonResponseJSON<MemberRegisterResultJSON>) => {
      return CommonResponseDTO.from(
        response,
        MemberRegisterResultDTO.fromJSON,
      )
    })
  }

  postPreBelongToBucket(
    memberPreJoinBucketDTO: MemberPreJoinBucketDTO,
  ): Promise<CommonResponseDTO<Nullable<MobileCarrierStartPaymentInfoDTO>>> {
    return this.fetchWrapper.postJson(`${this.serverUrl}/v1/m/r/members/self/prebucket`,
      DTO.toObject(memberPreJoinBucketDTO), withMembersAuth(),
    ).then((response: CommonResponseJSON<MobileCarrierStartPaymentInfoDTO>) => {
      return CommonResponseDTO.from(
        response,
        MobileCarrierStartPaymentInfoDTO.fromJSON,
      )
    })
  }

  getJoinPreRegisterInfo(
    temporaryEnrollmentId: string,
  ): Promise<CommonResponseDTO<Nullable<JoinPreRegisterInfoDTO>>> {
    return this.fetchWrapper.getByJson(
      `${this.serverUrl}/v1/m/r/members/self/prebucket/${temporaryEnrollmentId}`,
      withMembersAuth(),
    )
      .then((response: CommonResponseJSON<JoinPreRegisterInfoJSON>) => {
        return CommonResponseDTO.from(
          response,
          JoinPreRegisterInfoDTO.fromJSON,
        )
      })
  }

  getBelongToBucketComplete(
    temporaryEnrollmentId: string,
  ): Promise<CommonResponseDTO<Nullable<MemberRegisterResultDTO>>> {
    return this.fetchWrapper.getByJson(`${this.serverUrl}/v1/m/r/members/self/bucket/join/${temporaryEnrollmentId}`,
      withMembersAuth(),
    ).then((response: CommonResponseJSON<MemberRegisterResultJSON>) => {
      return CommonResponseDTO.from(
        response,
        MemberRegisterResultDTO.fromJSON,
      )
    })
  }

  putBucketContractInfo(
    memberEditContractDTO: MemberEditContractDTO,
  ): Promise<CommonResponseDTO<Nullable<MobileCarrierStartPaymentInfoDTO>>> {
    return this.fetchWrapper.putJson(`${this.serverUrl}/v1/m/r/members/self/contracts/${memberEditContractDTO.bucketKey}`,
      {...memberEditContractDTO.contractInputInfo},
      withMembersAuth(),
    ).then((response: CommonResponseJSON<MobileCarrierStartPaymentInfo>) => {
      return CommonResponseDTO.from(
        response,
        MobileCarrierStartPaymentInfoDTO.fromJSON,
      )
    })
  }

  putBucketContractInfoConfirm(
    temporaryEnrollmentId: string,
  ): Promise<CommonResponseDTO<Nullable<BucketInfoDTO>>> {
    return this.fetchWrapper.putJson(
      `${this.serverUrl}/v1/m/p/members/contracts/${temporaryEnrollmentId}/credit_card_update`,
      {},
    ).then((response: CommonResponseJSON<BucketInfoJSON>) => {
      return CommonResponseDTO.from(
        response,
        BucketInfoDTO.fromJSON,
      )
    })
  }

  deleteCancelBucketLeave(
    dto: MemberCancelBucketLeaveDTO,
  ): Promise<CommonResponseDTO<Nullable<MobileCarrierStartPaymentInfoDTO>>> {
    return this.fetchWrapper.postJson(`${this.serverUrl}/v1/m/r/members/self/buckets/${dto.bucketKey}/leave/delete`,
      {...dto.contractInputInfo},
      withMembersAuth(),
    ).then((response: CommonResponseJSON<MobileCarrierStartPaymentInfo>) => {
      return CommonResponseDTO.from(
        response,
        MobileCarrierStartPaymentInfoDTO.fromJSON,
      )
    })
  }

  deleteCancelBucketLeaveConfirm(
    temporaryEnrollmentId: string,
  ): Promise<CommonResponseDTO<Nullable<BucketInfoDTO>>> {
    return this.fetchWrapper.deleteJson(
      `${this.serverUrl}/v1/m/p/members/contracts/${temporaryEnrollmentId}/credit_card_reactive`,
      {},
    ).then((response: CommonResponseJSON<BucketInfoJSON>) => {
      return CommonResponseDTO.from(
        response,
        BucketInfoDTO.fromJSON,
      )
    })
  }


  getPaymentMethodListBySignupKey(signupKey: string)
    : Promise<CommonResponseDTO<PaymentMethodDTO[]>> {
    return this.fetchWrapper.getByJson(
      `${this.serverUrl}/v1/m/r/paymentmethods/${signupKey}`,
      withMembersAuth(),
    )
      .then((response: CommonResponseJSON<PaymentMethodJSON[]>) => {
        return CommonResponseDTO.fromArray(
          response,
          PaymentMethodDTO.fromJSON,
        )
      })
  }

  getBucketInfoByBucketKey(
    bucketKey: string,
  ): Promise<CommonResponseDTO<Nullable<BucketInfoDTO>>> {
    return this.fetchWrapper.getByJson(`${this.serverUrl}/v1/m/p/buckets/${bucketKey}`)
      .then((response: CommonResponseJSON<BucketInfoJSON & { key: string }>) => {
        const {status, data, errorMessageList} = response
        return CommonResponseDTO.from(
          {
            status: status,
            data: {...data, key: bucketKey},
            errorMessageList: errorMessageList,
          },
          BucketInfoDTO.fromJSON,
        )
      })
  }

  getMemberBucketOptionsByBucketKey(
    bucketKey: string,
  ): Promise<CommonResponseDTO<Nullable<MemberBucketOptionsDTO>>> {
    return this.fetchWrapper.getByJson(
      `${this.serverUrl}/v1/m/r/members/self/${bucketKey}/bucket_options`, withMembersAuth())
      .then((response: CommonResponseJSON<MemberBucketOptionsJSON>) => {
        return CommonResponseDTO.from(
          response,
          MemberBucketOptionsDTO.fromJSON,
        )
      })
  }

  getBucketLeaveByBucketKey(
    bucketKey: string,
  ): Promise<CommonResponseDTO<Nullable<BucketLeaveDTO>>> {
    return this.fetchWrapper.getByJson(
      `${this.serverUrl}/v1/m/r/members/self/buckets/${bucketKey}/leave`, withMembersAuth())
      .then((response: CommonResponseJSON<BucketLeaveJSON>) => {
        return CommonResponseDTO.from(
          response,
          BucketLeaveDTO.fromJSON,
        )
      })
  }

  getContractInfoByBucketKey(
    bucketKey: string,
  ): Promise<CommonResponseDTO<Nullable<MemberContractInfoDTO>>> {
    return this.fetchWrapper.getByJson(
      `${this.serverUrl}/v1/m/r/members/self/contracts/${bucketKey}`, withMembersAuth())
      .then((response: CommonResponseJSON<MemberContractInfoJSON>) => {
        return CommonResponseDTO.from(
          response,
          MemberContractInfoDTO.fromJSON,
        )
      })
  }

  getCancelBucketLeaveResult(
    temporaryEnrollmentId: string,
  ): Promise<CommonResponseDTO<Nullable<CancelBucketLeaveResultDTO>>> {
    return this.fetchWrapper.getByJson(
      `${this.serverUrl}/v1/m/r/buckets/${temporaryEnrollmentId}`, withMembersAuth(),
    )
      .then((response: CommonResponseJSON<CancelBucketLeaveResultJSON>) => {
        return CommonResponseDTO.from(
          response,
          CancelBucketLeaveResultDTO.fromJSON,
        )
      })
  }

  postLogout(): Promise<CommonResponseDTO<null>> {
    return this.fetchWrapper.postJson(
      `${this.serverUrl}/v1/m/r/members/self/logout`, {}, withMembersAuth())
      .then((response: CommonResponseJSON<null>) => CommonResponseDTO.ofNullData(response))
  }

  postVerifyIdentityMail(
    memberVerifyIdentityDTO: MemberVerifyIdentityDTO,
  ): Promise<CommonResponseDTO<null>> {
    return this.fetchWrapper.postJson(
      `${this.serverUrl}/v1/m/p/members/password/reset`,
      DTO.toObject(memberVerifyIdentityDTO),
    ).then((response: CommonResponseJSON<null>) => CommonResponseDTO.ofNullData(response))
  }

  getVerifyIdentityInfo(
    oneTimeToken: string,
  ): Promise<CommonResponseDTO<Nullable<MemberVerifyIdentityInfoDTO>>> {
    return this.fetchWrapper.getByJson(
      `${this.serverUrl}/v1/m/p/members/password/reset/${oneTimeToken}`,
    ).then((response: CommonResponseJSON<MemberVerifyIdentityInfoJSON>) => {
      return CommonResponseDTO.from(
        response,
        MemberVerifyIdentityInfoDTO.fromJSON,
      )
    })
  }

  putResetPassword(oneTimeToken: string, password: string): Promise<CommonResponseDTO<null>> {
    return this.fetchWrapper.putJson(
      `${this.serverUrl}/v1/m/p/members/password/reset/${oneTimeToken}`,
      {password},
    ).then((response: CommonResponseJSON<null>) => CommonResponseDTO.ofNullData(response))
  }

  deleteCreditCard(cardSeq: string): Promise<CommonResponseDTO<null>> {
    return this.fetchWrapper.deleteJson(
      `${this.serverUrl}/v1/m/r/members/self/credit_cards/${cardSeq}`,
      {},
      withMembersAuth(),
    ).then((response: CommonResponseJSON<null>) => CommonResponseDTO.ofNullData(response))
  }

  postShippingAddress(shippingAddress: MemberShippingAddressDTO): Promise<CommonResponseDTO<null>> {
    return this.fetchWrapper.postJson(
      `${this.serverUrl}/v1/m/r/members/self/shipping_addresses`,
      DTO.toObject(shippingAddress), withMembersAuth(),
    ).then((response: CommonResponseJSON<null>) => CommonResponseDTO.ofNullData(response))
  }

  getShippingAddressList(): Promise<CommonResponseDTO<Nullable<MemberShippingAddressInfoDTO[]>>> {
    return this.fetchWrapper.getByJson(
      `${this.serverUrl}/v1/m/r/members/self/shipping_addresses`, withMembersAuth(),
    ).then((response: CommonResponseJSON<ShippingAddressInfoJSON[]>) => {
      return CommonResponseDTO.fromArray(
        response,
        MemberShippingAddressInfoDTO.fromJSON,
      )
    })
  }

  putShippingAddress(
    shippingAddressInfo: MemberShippingAddressInfoDTO,
  ): Promise<CommonResponseDTO<null>> {
    const {shippingAddressId, ...rest} = shippingAddressInfo
    return this.fetchWrapper.putJson(
      `${this.serverUrl}/v1/m/r/members/self/shipping_addresses/${shippingAddressId}`,
      DTO.toObject(rest), withMembersAuth(),
    ).then((response: CommonResponseJSON<null>) => CommonResponseDTO.ofNullData(response))
  }

  deleteShippingAddress(shippingAddressId: number): Promise<CommonResponseDTO<null>> {
    return this.fetchWrapper.deleteJson(
      `${this.serverUrl}/v1/m/r/members/self/shipping_addresses/${shippingAddressId}`,
      {},
      withMembersAuth(),
    ).then((response: CommonResponseJSON<null>) => CommonResponseDTO.ofNullData(response))
  }

  postContinuanceCvs(
    bucketKey: string,
    convenienceStoreKey: ConvenienceStoreKey,
  ): Promise<CommonResponseDTO<null>> {
    return this.fetchWrapper.postJson(
      `${this.serverUrl}/v1/m/r/members/self/contracts/${bucketKey}/continuance_convenience_store`,
      {convenienceStoreId: convenienceStoreKey},
      withMembersAuth(),
    ).then((response: CommonResponseJSON<null>) => CommonResponseDTO.ofNullData(response))
  }

  getSignedUpBucketInfoList(): Promise<CommonResponseDTO<Nullable<SignedUpBucketInfoDTO[]>>> {
    return this.fetchWrapper.getByJson(`${this.serverUrl}/v1/m/r/members/self/buckets`, withMembersAuth())
      .then((response: CommonResponseJSON<SignedUpBucketInfoJSON[]>) => {
        return CommonResponseDTO.fromArray(
          response,
          SignedUpBucketInfoDTO.fromJSON,
        )
      })
  }

  deleteAccount(): Promise<CommonResponseDTO<null>> {
    return this.fetchWrapper.deleteJson(
      `${this.serverUrl}/v1/m/r/members/self`,
      {},
      withMembersAuth(),
    ).then((response: CommonResponseJSON<null>) => CommonResponseDTO.ofNullData(response))
  }
}

export default NetworkMemberRepo
